import React, { FC } from 'react';

export default function Dash_footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-md-12 col-sm-12 text-center">
                        Copyright © 2023 . developed by Innosyz                          
                        All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
};


