import React from "react";
import { Outlet } from "react-router-dom";


const Firebaselayout = () => {
  
  return (
  <Outlet />
  );
};

export default Firebaselayout;