import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../../assets/images/search.png";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

function Employelist() {
    const navigate = useNavigate()
    const schema = yup.object().shape({
        departmentName: yup.string().required("Department Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    useEffect(() => {
        onSubmit()
    }, [])
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 1;

    const onSubmit = async (value) => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Employee/GetHREmployeeList`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setData(response.data.data)
        })
            .catch((error) => {
                setLoading(false)
            })
    };
    const addmore = () => {
        navigate('/add-employe')
    }
    const editfunction = (data) => {
        navigate('/edit-employe', { state: { id: data } });
    }
    const deletefunction = async (id) => {
        const upload = {
            empId: parseInt(id),
        }
        setLoading(true)
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        try {
                            axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/Employee/DeleteEmployeeById`, {
                                data: upload,
                            }).then((response) => {
                                toast.success(response.data.message);
                                setLoading(false);
                                window.location.reload();
                            }).catch((error) => {
                                setLoading(false);
                                console.log(error);
                            });
                        } catch (error) {
                            setLoading(false);
                            console.log(error);
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No'),
                },
            ],
        });
    }
    const handleKeyDown = (e) => {
        // Check if the pressed key is "Enter"
        if (e.key === 'Enter') {
            // Call your submit function here
            onSubmit({ departmentName: e.target.value });
        }
    };
  return (
    <>
    <Loader loading={loading} />
    <div class="col-xl-12">
        <div class="card custom-card">
            <div class="card-header justify-content-between">
                <div class="card-title">
                    Employees
                </div>
                <div class="prism-toggle">
                    <button class="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i class="ri-code-line ms-2 d-inline-block align-middle"></i></button>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div class="gridjs-search mt-1 mb-2">
                        <input type="search" placeholder="Type a keyword..." name='empName' onKeyDown={handleKeyDown}  {...register("empName")} aria-label="Type a keyword..." class="gridjs-input gridjs-search-input" />
                    </div>
                    <div class="row">
                    {data?.map((contact, index) => {
                                return (
                        <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12"> 
                            <div class="card"> 
                                <div class="card-body contact-action">                                
                                <div class="d-flex align-items-top"> 
                                <div class="d-flex flex-fill flex-wrap gap-3"> 
                                <div class="avatar avatar-xl avatar-rounded"> 
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/1200px-User_icon_2.svg.png" alt="usericon"/> 
                                </div> 
                                <div> 
                                <h6 class="mb-1 fw-semibold"> {contact.firstName} {contact.lastName} </h6>
                                <p class="mb-1 fw-semibold"> {contact.employeeCode} </p>  
                                <p class="mb-1 text-muted contact-mail text-truncate">{contact.email}</p>
                                <p class="mb-1 text-muted contact-mail text-truncate">{contact.designationName}</p>
                                <p class="fw-semibold fs-11 mb-0 text-primary">{contact.phoneNumber}</p>
                                </div> 
                                </div>  
                                </div>
                                </div>
                            </div>
                        </div>



/* {<div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12"> 
<div class="card custom-card"> 
<div class="card-body contact-action"> 
<div class="contact-overlay"></div>
 <div class="d-flex align-items-top"> 
 <div class="d-flex flex-fill flex-wrap gap-3">
     <div class="avatar avatar-xl avatar-rounded"> 
     <img src="../assets/images/faces/6.jpg" alt=""/> 
     </div> <div> <h6 class="mb-1 fw-semibold"> Ramika Missi </h6> 
     <p class="mb-1 text-muted contact-mail text-truncate">ramikamissi@gmail.com</p>
     <p class="fw-semibold fs-11 mb-0 text-primary"> +1(555) 982 4834 </p>
     </div> 
     </div> 
     <div> <i class="ri-heart-3-line fs-16 text-muted"></i> 
     </div> </div> 
     <div class="d-flex align-items-center justify-content-center gap-2 contact-hover-buttons"> 
     <button type="button" class="btn btn-sm btn-light contact-hover-btn"> View Contact </button> 
     <div class="dropdown contact-hover-dropdown"> 
     <button class="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
         <i class="ri-more-2-fill"></i> </button> 
         <ul class="dropdown-menu" style=""> 
         <li><a class="dropdown-item" href="javascript:void(0);"><
            i class="ri-share-line me-2 align-middle d-inline-block"></i>Share</a>
            </li> <li><a class="dropdown-item" href="javascript:void(0);">
                <i class="ri-phone-line me-2 align-middle d-inline-block"></i>Call</a>
                </li> <li><a class="dropdown-item" href="javascript:void(0);">
                    <i class="ri-chat-2-line me-2 align-middle d-inline-block"></i>Message</a>
                    </li> <li><a class="dropdown-item" href="javascript:void(0);">
                        <i class="ri-video-chat-line me-2 align-middle d-inline-block"></i>Video Call</a>
                        </li> 
                        <li><a class="dropdown-item" href="javascript:void(0);">
                            <i class="ri-delete-bin-5-line me-2 align-middle d-inline-block"></i>Delete</a></li>
                             <li><a class="dropdown-item" href="javascript:void(0);">
        <i class="ri ri-heart-3-line me-2 align-middle d-inline-block"></i>Favourite</a></li> </ul> </div>
      <button aria-label="button" class="btn btn-sm btn-icon btn-light contact-hover-dropdown1" type="button"> 
     <i class="ri-heart-3-fill text-danger"></i>
 </button> </div> </div> </div> </div> }*/


                        )
                    })}
                    </div> 
                        
                    {/* <table class="gridjs-table">
                        <thead>
                            <tr>                               
                                <th className='gridjs-th' scope="col">Name</th>
                                <th className='gridjs-th' scope="col">Department</th>
                                <th className='gridjs-th' scope="col">Designation</th>
                                <th className='gridjs-th' scope="col">Date of joining</th>
                                <th className='gridjs-th' scope="col">Date of birth</th>
                           
                                <th className='gridjs-th' scope="col">Phone</th>
                                <th className='gridjs-th' scope="col">Email</th>
                                <th className='gridjs-th' scope="col">Gender</th>
                                <th className='gridjs-th' scope="col">Address</th>
                                <th className='gridjs-th' scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((contact, index) => {
                                return (
                                    <tr key={index}>                                       
                                        <td class="gridjs-td">{contact.firstName} {contact.lastName}</td>
                                        <td class="gridjs-td">{contact.departmentName}</td>
                                        <td class="gridjs-td">{contact.designationName}</td>
                                        <td class="gridjs-td">{contact.hireDate?.slice(0,10)}</td>
                                        <td class="gridjs-td">{contact.birthdate?.slice(0,10)}</td>
                                      
                                        <td class="gridjs-td">{contact.phoneNumber}</td>
                                        <td class="gridjs-td">{contact.email}</td>
                                        <td class="gridjs-td">{contact.gender}</td>
                                        <td class="gridjs-td">{contact.personalAddress}</td>
                                        <td class="gridjs-td">
                                            <div class="hstack gap-2 fs-15">
                                                <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.employeeId)}><img src={edit} /></a>
                                                <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.employeeId)}><img src={del} /></a>
                                            </div>
                                        </td>

                                    </tr>
                                    )
                            })}

                        </tbody>
                    </table> */}
                    <div class="gridjs-footer"><div class="gridjs-pagination">
                        <div class="gridjs-pages">
                            <ResponsivePagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={setCurrentPage}
                            />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</>
  )
}

export default Employelist
