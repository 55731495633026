import React from 'react';

const MenuItems = [
    {
        menutitle: "MAIN",
        id: 1,
        Items: [
            {
                path: `dashboard`,
                icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Dashboard'
            },
        ]
    },
    {
        menutitle: "COMPONENTS",
        id: 2,
        Items: [
            {
                path: `dashboard`,
                icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24"
                    height="24" viewBox="0 0 24 24">
                    <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z" /></svg>),
                type: 'sub',
                active: false,
                selected: false,
                title: 'Master',
                children: [
                    { path: `department-list`, type: 'link', active: false, selected: false, title: 'Department' },
                    { path: `designation-list`, type: 'link', active: false, selected: false, title: 'Designation' },
                    { path: `company-list`, type: 'link', active: false, selected: false, title: 'Company' },
                    { path: `unit-list`, type: 'link', active: false, selected: false, title: 'Units' },
                    { path: `supplier-list`, type: 'link', active: false, selected: false, title: 'Supplier' },
                    { path: `supplier-contact-list`, type: 'link', active: false, selected: false, title: 'Supplier Contacts' },
                    { path: `project-list`, type: 'link', active: false, selected: false, title: 'Projects' },
                    { path: `employe-list`, type: 'link', active: false, selected: false, title: 'Employee' },
                   
                ],
            },
            {
                path: `dashboard`,
                icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24"
                    height="24" viewBox="0 0 24 24">
                    <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z" /></svg>),
                type: 'sub',
                active: false,
                selected: false,
                title: 'MR',
                children: [
                    // { path: `MR`, type: 'link', active: false, selected: false, title: 'MR' },
                    { path: `MR-list`, type: 'link', active: false, selected: false, title: 'List' },
                ],
            },

            // {
            //     path: `order-details`,
            //     icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24"
            //         height="24" viewBox="0 0 24 24">
            //         <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z" /></svg>),
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Order Details',
            // },
           
        ],
    },
];
export default MenuItems;

