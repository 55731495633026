import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";

function EditProjects() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({

    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        fetchData();
    }, [location.state.id, setValue, setLoading]);
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectById?ProjectId=${parseInt(location.state.id)}`
            );

            const companyData = response.data.data;
            setValue("projectName", companyData.projectName);
            setValue("projectCode", companyData.projectCode);
            setValue("projectDescription", companyData.projectDescription);
            setValue("projectStartDate", companyData.projectStartDate);
            setValue("projectEndDate", companyData.projectEndDate);
            setValue("projectStatus", companyData.projectStatus);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching company data:", error);
            setLoading(false)
        }
    };
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            offset: 0,
            recordCount: 0,
            company_Name: data?.company_Name,
            company_Address: data?.company_Address,
            company_City: data?.company_City,
            company_Country: data?.company_Country,
            company_Email: data?.company_Email,
            company_Telephone: data?.company_Telephone,
            company_Phone: data?.company_Phone,
            company_Logo: '',
            company_Currency: data?.company_Currency,
            company_Id: 0
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Company/CreateCompany`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/supplier-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const [img, setImg] = useState()
    const handleImageChange = (e) => {
        setImg(e.target.files[0])

    };
    return (
        <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Edit Project</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={4} md={12} sm={12}>
                </Col>
                <Col xl={4} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='projectCode'
                                        placeholder="projectCode
                                "
                                        {...register("projectCode")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.projectCode ? errors.projectCode.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='projectName'
                                        placeholder="projectName"
                                        {...register("projectName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.projectName ? errors.projectName.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='projectDescription'
                                        placeholder="projectDescription"
                                        {...register("projectDescription")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.projectDescription ? errors.projectDescription.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='projectStartDate'
                                        placeholder="projectStartDate"
                                        {...register("projectStartDate")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.projectStartDate ? errors.projectStartDate.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='projectEndDate'
                                        placeholder="projectEndDate"
                                        {...register("projectEndDate")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.company_Currency ? errors.company_Currency.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" name='company_Country' {...register("projectStatus")}>
                                        <option value={1} disabled>Active</option>
                                        <option value={0}>De Active</option>
                                    </Form.Control>
                                    <p className="errorMsg pl-3 mt-2">{errors.projectStatus ? errors.projectStatus.message : ''}</p>
                                </Form.Group>
                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
    )
}

export default EditProjects
