import React, { FC, Fragment } from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import { ResponsiveDataTable } from './data/responsivedata';
import PageHeader from '../../layouts/pageheader';

export default function DataTable() {
	return(
	<Fragment>
		<PageHeader title="Data Tables" />
		<Row>
			<Col className="col-12">
				<Card>
					<Card.Header>
						<Card.Title>Responsive DataTable</Card.Title>
					</Card.Header>
					<Card.Body>
						<ResponsiveDataTable />
					</Card.Body>
				</Card>				
			</Col>
		</Row>
	</Fragment>
	)
};


