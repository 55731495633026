
import media1 from '../../assets/images/media/1.jpg';
import media2 from '../../assets/images/media/2.jpg';
import media3 from '../../assets/images/media/3.jpg';
import media4 from '../../assets/images/media/4.jpg';
import media5 from '../../assets/images/media/5.jpg';
import media6 from '../../assets/images/media/6.jpg';
import media7 from '../../assets/images/media/7.jpg';
import media8 from '../../assets/images/media/8.jpg';
import media9 from '../../assets/images/media/9.jpg';
import media10 from '../../assets/images/media/10.jpg';
import media11 from '../../assets/images/media/11.jpg';
import media12 from '../../assets/images/media/12.jpg';
import products16 from '../../assets/images/products/16.jpg';
import products15 from "../../assets/images/products/15.jpg";
import products13 from "../../assets/images/products/13.jpg";
import products18 from "../../assets/images/products/18.jpg";
import media13 from '../../assets/images/media/13.jpg';
import media14 from '../../assets/images/media/14.jpg';
import media15 from '../../assets/images/media/15.jpg';
import media16 from '../../assets/images/media/16.jpg';
import users16 from '../../assets/images/users/16.jpg';
import users10 from '../../assets/images/users/10.jpg';
import Logo from '../../assets/images/brand/logo.png';
import Logo1 from '../../assets/images/brand/logo1.png';
import flags5 from '../../assets/images/flags/flags/5.jpg';
import flags1 from '../../assets/images/flags/flags/1.jpg';
import flags2 from '../../assets/images/flags/flags/2.jpg';
import flags3 from '../../assets/images/flags/flags/3.jpg';
import flags4 from '../../assets/images/flags/flags/4.jpg';
import users2 from '../../assets/images/users/2.jpg';
import media19 from '../../assets/images/media/19.jpg';
import media20 from '../../assets/images/media/20.jpg';
import media21 from '../../assets/images/media/21.jpg';
import media22 from '../../assets/images/media/22.jpg';
import media23 from '../../assets/images/media/23.jpg';
import media24 from '../../assets/images/media/24.jpg';
import media25 from '../../assets/images/media/25.jpg';
import media17 from '../../assets/images/media/17.jpg';
import media18 from '../../assets/images/media/18.jpg';
import media28 from '../../assets/images/media/28.jpg';
import media29 from '../../assets/images/media/29.jpg';
import media30 from '../../assets/images/media/30.jpg';
import users4 from '../../assets/images/users/4.jpg';
import users8 from '../../assets/images/users/8.jpg';
import users9 from '../../assets/images/users/9.jpg';
import users11 from '../../assets/images/users/11.jpg';
import users12 from '../../assets/images/users/12.jpg';
import Favicon from '../../assets/images/brand/favicon.png';
import Favicon1 from '../../assets/images/brand/favicon1.png';
import pngs10 from '../../assets/images/pngs/10.png';
import pngs13 from '../../assets/images/pngs/13.png';
import pngs12 from '../../assets/images/pngs/12.png';
import pngs6 from '../../assets/images/pngs/6.png';
import users5 from '../../assets/images/users/5.jpg';
import users7 from '../../assets/images/users/7.jpg';
import users3 from '../../assets/images/users/3.jpg';
import users6 from '../../assets/images/users/6.jpg';
import users13 from '../../assets/images/users/13.jpg';
import users14 from '../../assets/images/users/14.jpg';
import users1 from '../../assets/images/users/1.jpg';
import pngs2 from '../../assets/images/pngs/2.png';
import pngs3 from '../../assets/images/pngs/3.png';
import pngs8 from '../../assets/images/pngs/8.png';
import media44 from '../../assets/images/media/44.jpg';
import media45 from '../../assets/images/media/45.jpg';
import media46 from '../../assets/images/media/46.jpg';
import media47 from '../../assets/images/media/47.jpg';
import media48 from '../../assets/images/media/48.jpg';
import media49 from '../../assets/images/media/49.jpg';
import media37 from '../../assets/images/media/37.jpg';
import media38 from '../../assets/images/media/38.jpg';
import media39 from '../../assets/images/media/39.jpg';
import media40 from '../../assets/images/media/40.jpg';
import media41 from '../../assets/images/media/41.jpg';
import media42 from '../../assets/images/media/42.jpg';
import media43 from '../../assets/images/media/43.jpg';
import pngs4 from '../../assets/images/pngs/4.png';
import pngs5 from '../../assets/images/pngs/5.png';
import pngs7 from '../../assets/images/pngs/7.png';
import pngs9 from '../../assets/images/pngs/9.png';
import Loader from '../../assets/images/svgs/loader.svg';
import users15 from '../../assets/images/users/15.jpg';
import products1 from '../../assets/images/products/1.jpg';
import products2 from '../../assets/images/products/2.jpg';
import products3 from '../../assets/images/products/3.jpg';
import products4 from '../../assets/images/products/4.jpg';
import products5 from '../../assets/images/products/5.jpg';
import products6 from '../../assets/images/products/6.jpg';
import products7 from '../../assets/images/products/7.jpg';
import products8 from '../../assets/images/products/8.jpg';
import products9 from '../../assets/images/products/9.jpg';
import pngs15 from '../../assets/images/pngs/15.png';
import pngs16 from '../../assets/images/pngs/16.png';
import pngs17 from '../../assets/images/pngs/17.png';
import pngs18 from '../../assets/images/pngs/18.png';
import pngs1 from '../../assets/images/pngs/1.png';
import products12 from '../../assets/images/products/12.jpg';
import products14 from '../../assets/images/products/14.jpg';
import products19 from '../../assets/images/products/19.jpg';
import products20 from '../../assets/images/products/20.jpg';
import products21 from '../../assets/images/products/21.jpg';
import media26 from '../../assets/images/media/26.jpg';
import products17 from '../../assets/images/products/17.jpg';
import products10 from '../../assets/images/products/10.jpg';
import products11 from '../../assets/images/products/11.jpg';
import media27 from '../../assets/images/media/27.jpg';
import firebase from '../../assets/images/brand/firbase.png';
import react from '../../assets/images/brand/react.png';
import logo from '../../assets/images/brand/logo.png';
import logo1 from '../../assets/images/brand/logo1.png';
import favicon from '../../assets/images/brand/favicon.png';
import favicon1 from '../../assets/images/brand/favicon1.png';
import flag1 from '../../assets/images/flags/flags/1.jpg';
import flag2 from '../../assets/images/flags/flags/2.jpg';
import flag3 from '../../assets/images/flags/flags/3.jpg';
import flag4 from '../../assets/images/flags/flags/4.jpg';
import flag5 from '../../assets/images/flags/flags/5.jpg';

import products71 from '../../assets/images/products/71.jpg';
import products72 from '../../assets/images/products/72.jpg';
import products81 from '../../assets/images/products/81.jpg';
import products82 from '../../assets/images/products/82.jpg';
import products91 from '../../assets/images/products/91.jpg';
import products92 from '../../assets/images/products/92.jpg';
import products111 from '../../assets/images/products/112.jpg';
import products112 from '../../assets/images/products/1.jpg';
import products211 from '../../assets/images/products/211.jpg';
import products212 from '../../assets/images/products/212.jpg';
import products31 from '../../assets/images/products/31.jpg';
import products33 from '../../assets/images/products/33.jpg';
import products41 from '../../assets/images/products/41.jpg';
import products42 from '../../assets/images/products/42.jpg';
import products51 from '../../assets/images/products/51.jpg';
import products52 from '../../assets/images/products/52.jpg';
import products61 from '../../assets/images/products/61.jpg';
import products62 from '../../assets/images/products/62.jpg';

export const ImagesData = (img) => {

    const photo = { media1, media2, media3, media4, media5, media6, media7, media8, media9, media10, media11, media12, products16, products15, products13, products18, media13, media14, media15, media16, users16, users10, Logo, Logo1, flags5, flags1, flags2, flags3, flags4, users2, media19, media20, media21, media22, media23, media24, media25, media17, media18, media28, media29, media30, users4, users8, users9, users11, users12, Favicon, Favicon1, pngs10, users5, users7, users3, users6, users13, users14, users1, pngs2, pngs3, pngs8, pngs13, pngs12, media44, media45, media46, media47, media48, media49, media37, media38, media39, media40, media41, media42, media43, pngs4, pngs5, pngs7, pngs9, Loader, users15, products1, products2, products3, products4, products5, products6, products7, products8, products9, pngs15, pngs16, pngs17, pngs18, products12, products14, products19, products20, products21, media26, products17, products10, products11, media27, pngs6, pngs1, firebase, react, logo, logo1, favicon, favicon1, flag1, flag2, flag3, flag4, flag5, products71, products72, products81, products82, products91, products92, products111, products112, products211, products212, products31, products33, products41, products42, products51, products52, products61, products62 };

    return photo[img];
};
