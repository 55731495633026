import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../../assets/images/search.png";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';


function DesignationList() {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 1;
    const schema = yup.object().shape({
        designationName: yup.string().required("Designation Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    useEffect(() => {
        onSubmit()
    }, [])

    const onSubmit = async (value) => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Designation/GetDesignationList?DesignationName=${value?.designationName ? value?.designationName : ''}`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setData(response.data.data)
        })
            .catch((error) => {
                setLoading(false)
            })
    };
    const cleartheintervals = () => {
        document.getElementById('designationName').value = '';
        setValue('')
        onSubmit()
    }
    const addmore = () => {
        navigate('/add-designation')
    }
    const editfunction = (data) => {
        navigate('/edit-designation', { state: { id: data } });
    }
    const deletefunction = async (id) => {
        const upload = {
            designationName: '',
            userId: "0",
            offset: 0,
            recordCount: 0,
            designationId: parseInt(id),
        }
        setLoading(true)
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        try {
                            axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/Designation/DeleteDDesignationById`, {
                                data: upload,
                            }).then((response) => {
                                toast.success(response.data.message);
                                setLoading(false);
                                window.location.reload();
                            }).catch((error) => {
                                setLoading(false);
                                console.log(error);
                            });
                        } catch (error) {
                            setLoading(false);
                            console.log(error);
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No'),
                },
            ],
        });
    }
    const handleKeyDown = (e) => {
        // Check if the pressed key is "Enter"
        if (e.key === 'Enter') {
            // Call your submit function here
            onSubmit({ departmentName: e.target.value });
        }
    };
    return (
        <>
            <Loader loading={loading} />
            <div class="col-xl-12">
                <div class="card custom-card">
                    <div class="card-header justify-content-between">
                        <div class="card-title">
                            Company
                        </div>
                        <div class="prism-toggle">
                            <button class="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i class="ri-code-line ms-2 d-inline-block align-middle"></i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <div class="gridjs-search mt-1 mb-2">
                                <input type="search" placeholder="Type a keyword..." name='designationName' onKeyDown={handleKeyDown}  {...register("designationName")} aria-label="Type a keyword..." class="gridjs-input gridjs-search-input" />
                            </div>
                            <table class="gridjs-table">
                                <thead>
                                    <tr>
                                        <th className='gridjs-th'>S NO</th>
                                        <th className='gridjs-th'>Designations</th>
                                        <th className='gridjs-th'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((contact, index) => {
                                        return (
                                            <tr key={index}>
                                                <td class="gridjs-td">{index}</td>
                                                <td class="gridjs-td">{contact.designationName}</td>
                                                <td class="gridjs-td">
                                                    <div class="hstack gap-2 fs-15">
                                                        <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.designationId)} ><img src={edit} /></a>
                                                        <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.designationId)}><img src={del} /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                            <div class="gridjs-footer"><div class="gridjs-pagination">
                                <div class="gridjs-pages">
                                    <ResponsivePagination
                                        current={currentPage}
                                        total={totalPages}
                                        onPageChange={setCurrentPage}
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignationList
