import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddEmploye() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const schema = yup.object().shape({
        empName: yup.string().required("Name required"),
        empCode: yup.string().required("Code required"),
        empAddress: yup.string().required("Address required"),
        empPhoneNumber: yup.string().required("Phone number required"),      

    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        designations()
        Departments()
    }, [])
    const [msg1, setMsg1] = useState();
    const [msg2, setMsg2] = useState();
    const [msg3, setMsg3] = useState();
    const [msg4, setMsg4] = useState();
    const [msg5, setMsg5] = useState();
    const [location, setLocation] = useState([]);
    const [selectedgender, setSelectedGender] = useState([]);
    const [selectedmaritalstatus, setSelectedMaritalStatus] = useState([]);
    const [birthDate, setBirthDate] = useState();
    const [hireDate, setHireDate] = useState();   
    const [department, setDepartment] = useState([])
    const [designation, setDesignation] = useState([])
    const [employeeid, setEmployeeId] = useState([])
    const [selectedlocation, setSelectedLocation] = useState([])
    const [selecteddepartment, setSelectedDepartment] = useState([])
    const [selecteddesignation, setSelectedDesignation] = useState([])
    const [selectedreportingEmpId, setSelectedReporingEmpId] = useState([])
    const designations = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Designation/GetDesignationList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.designationName,
                value: item.designationId
            }));
            setDesignation(formattedData)
        })
            .catch((error) => {
            })
    };
    const Departments = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Department/GetDepartmentList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.departmentName,
                value: item.departmentId
            }));
            setDepartment(formattedData)
        })
            .catch((error) => {
            })
    };
    const onSubmit = async (data) => {
        console.log(selecteddepartment, 'opopppp');
        if (typeof birthDate === 'undefined') {
            setMsg3('Date of birth is not selected')
        } else if (typeof hireDate === 'undefined') {
            setMsg4('Date of joining is not selected')
        } else if (!selecteddepartment) {
            setMsg1('Department is not selected')
        } else if (!selecteddesignation) {
            setMsg2('Designation is not selected')
        }else if (!selectedgender) {
            setMsg5('Gender is not selected')
        } else {
            const upload = {
                empId: 0,
                userId: "string",
                Salutation: data?.Salutation,
                EmployeeCode: data?.EmployeeCode,
                FirstName: data?.FirstName,
                FirstName: data?.FirstName,
                Birthdate: birthDate,
                Gender: selectedgender?.value,
                Email: data?.Email,
                WorkEmail: data?.WorkEmail,
                PhoneNumber: data?.PhoneNumber,
                WorkPhoneNumber: data?.WorkPhoneNumber,
                HireDate: hireDate,
                DepartmentID: selecteddepartment?.value,
                DesignationID: selecteddesignation?.value,
                MaritalStatus: selectedmaritalstatus?.value,                
                Salary: data?.Salary,
                CompanyAddress:data?.CompanyAddress,
                PersonalAddress:data?.PersonalAddress,
                BankName:data?.BankName,
                BankAcNumber:data?.BankAcNumber,
                BankIFSC:data?.BankIFSC,
                BankBranch:data?.BankBranch,
                BankAcNumber:data?.BankAcNumber,
                Status:1,
               
            }
            setLoading(true)
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Employee/CreateHREmployee`, upload);
                if (response.data.status == 1) {
                    toast.success(response.data.message);
                    navigate('/employe-list')
                    setLoading(false)
                }

            } catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
    }
    const handleSelectDepartment = (selectedOption) => {
        setSelectedDepartment(selectedOption);
    };
    const handleSelectLocation = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };
    const handleSelectDesignation = (selectedOption) => {
        setSelectedDesignation(selectedOption);
    };
    const handleSelectgender =(selectedOption)=>{
        setSelectedGender(selectedOption)
    }

    const handleSelectMaritalStatus =(selectedOption)=>{
        setSelectedMaritalStatus(selectedOption)
    }
    const handleSelectReportingEmpId =(selectedOption)=>{
        setSelectedReporingEmpId(selectedOption)
    }
    return (
        <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Add Employee</h4>
                    </div>
                    {/* <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div> */}
                </div>
            </Fragment>
            <Row>
                <Col xl={12} md={12} sm={12}>
                </Col>
                <Col xl={12} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                        <ul class="nav nav-tabs tab-style-1 d-sm-flex d-block" role="tablist"> 
                        <li class="nav-item" role="presentation"> 
                        <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab1" aria-current="page" href="#tab1" aria-selected="true" role="tab">Personal Details</a>
                         </li> 
                         <li class="nav-item" role="presentation"> 
                         <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" href="#tab2" aria-selected="false" role="tab" tabindex="-1">Official Details</a> 
                         </li> 
                         <li class="nav-item" role="presentation"> 
                         <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab3" href="#tab3" aria-selected="false" role="tab" tabindex="-1">Salary Details</a> 
                         </li> 
                         <li class="nav-item" role="presentation"> <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab4" href="#tab4" aria-selected="false" role="tab" tabindex="-1">Bank Info</a> 
                         </li> 
                         </ul>
                             <Form onSubmit={handleSubmit(onSubmit)}>
                             <div class="tab-content">
                             <div class="tab-pane active show" id="tab1" role="tabpanel">
                            <Row>
                                 <Form.Group as={Col} md="2" controlId="validationCustom01">
                                 <label  class="form-label">Salutation</label>
                                    <Form.Control
                                        type="text"
                                        name='Salutation'
                                        placeholder="Salutation"
                                        {...register("Salutation")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.Salutation ? errors.Salutation.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationCustom01">
                                <label  class="form-label">First Name</label>
                                <Form.Control
                                        type="text"
                                        name='FirstName'
                                        placeholder="FirstName"
                                        {...register("FirstName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.FirstName ? errors.FirstName.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationCustom01">
                                <label  class="form-label">Last Name</label>
                                <Form.Control
                                        type="text"
                                        name='LastName'
                                        placeholder="LastName"
                                        {...register("LastName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.LastName ? errors.LastName.message : ''}</p>
                                </Form.Group>
                                
                                </Row>
                               
                                <Row>
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label  class="form-label">Employee Code</label>
                                    <Form.Control
                                        type="text"
                                        name='EmployeeCode'
                                        placeholder="Employee Code"
                                        {...register("EmployeeCode")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.EmployeeCode ? errors.EmployeeCode.message : ''}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                    <label  class="form-label">Gender</label>
                                    <Select
                                        value={selectedgender}
                                        onChange={handleSelectgender}
                                        options={[{
                                            label:'Male',
                                            value:0
                                        },{
                                            label:'Female',
                                            value:1
                                        }]}
                                        isSearchable
                                        placeholder="Search Gender"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg5}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label  class="form-label">Birth Date</label>
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={birthDate} onChange={(date) => setBirthDate(date)} />
                                    </div>
                                    <p className="errorMsg pl-3 mt-2">{msg4}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label  class="form-label">MaritalStatus</label>
                                    <Select
                                        value={selectedmaritalstatus}
                                        onChange={handleSelectMaritalStatus}
                                        options={[{
                                            label:'Married',
                                            value:0
                                        },{
                                            label:'Unmarried',
                                            value:1
                                        },
                                        {
                                            label:'Divorced',
                                            value:2
                                        }
                                        ,{
                                            label:'Widow',
                                            value:3
                                        }]}
                                        isSearchable
                                        placeholder="Marital Status"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg5}</p>
                                </Form.Group>
                                </Row>

                                <Row>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Personal Email</label>
                                    <Form.Control
                                        type="text"
                                        name='Email'
                                        placeholder="Email"
                                        {...register("Email")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.Email ? errors.Email.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Personal Phone</label>
                                    <Form.Control
                                        type="text"
                                        name='PhoneNumber'
                                        placeholder="Phone Number"
                                        {...register("PhoneNumber")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.PhoneNumber ? errors.PhoneNumber.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Personal Address</label>
                                    <Form.Control
                                        type="text"
                                        name='PersonalAddress'
                                        placeholder="PersonalAddress"
                                        {...register("PersonalAddress")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.PersonalAddress ? errors.PersonalAddress.message : ''}</p>
                                </Form.Group>
                                </Row>

                                
                               
</div>
                                <div class="tab-pane" id="tab2" role="tabpanel">

                                <Row>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Work Email</label>
                                    <Form.Control
                                        type="text"
                                        name='Work Email'
                                        placeholder="WorkEmail"
                                        {...register("WorkEmail")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.WorkEmail ? errors.WorkEmail.message : ''}</p>
                                </Form.Group>                                

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Work Phone</label>
                                    <Form.Control
                                        type="text"
                                        name='WorkPhoneNumber'
                                        placeholder="Work Phone Number"
                                        {...register("PhoneNumber")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.WorkPhoneNumber ? errors.WorkPhoneNumber.message : ''}</p>
                                </Form.Group>

                                
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Hire Date</label>
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={hireDate} onChange={(date) => setHireDate(date)} />
                                    </div>
                                    <p className="errorMsg pl-3 mt-2">{msg4}</p>
                                </Form.Group>
                                </Row>


                                <Row>      
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Department</label>
                                    <Select
                                        value={selecteddepartment}
                                        onChange={handleSelectDepartment}
                                        options={department}
                                        isSearchable
                                        placeholder="Search Department"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Designation</label>
                                    <Select
                                        value={selecteddesignation}
                                        onChange={handleSelectDesignation}
                                        options={designation}
                                        isSearchable
                                        placeholder="Search Designation"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg2}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Reporting to</label>
                                    <Select
                                        value={selectedreportingEmpId}
                                        onChange={handleSelectReportingEmpId}
                                        options={employeeid}
                                        isSearchable
                                        placeholder="Search Employee"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg2}</p>
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label  class="form-label">Location</label>
                                    <Select
                                        value={selectedlocation}
                                        onChange={handleSelectLocation}
                                        options={location}
                                        isSearchable
                                        placeholder="Search Location"
                                    />
                                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="8" controlId="validationCustom01">
                                <label class="form-label">Company Address</label>
                                    <Form.Control
                                        type="text"
                                        name='CompanyAddress'
                                        placeholder="CompanyAddress"
                                        {...register("CompanyAddress")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.CompanyAddress ? errors.CompanyAddress.message : ''}</p>
                                </Form.Group>
                                </Row>
                                </div>
                                <div class="tab-pane" id="tab3" role="tabpanel">

                                <Row>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Salary</label>
                                    <Form.Control
                                        type="text"
                                        name='Salary'
                                        placeholder="Salary"
                                        {...register("Salary")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.Salary ? errors.Salary.message : ''}</p>
                                </Form.Group>
                                </Row>
                                </div>
                                <div class="tab-pane" id="tab4" role="tabpanel">

                                <Row>   
                              
                                
                              
                                </Row>
                                <Row> 
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Bank Name</label>
                                    <Form.Control
                                        type="text"
                                        name='BankName'
                                        placeholder="BankName"
                                        {...register("BankName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.BankName ? errors.BankName.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Bank AcNumber</label>
                                    <Form.Control
                                        type="text"
                                        name='BankAcNumber'
                                        placeholder="BankAcNumber"
                                        {...register("BankAcNumber")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.BankAcNumber ? errors.BankAcNumber.message : ''}</p>
                                </Form.Group>
                                </Row> 
                                <Row> 
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Bank IFSC</label>
                                    <Form.Control
                                        type="text"
                                        name='BankIFSC'
                                        placeholder="BankIFSC"
                                        {...register("BankIFSC")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.BankIFSC ? errors.BankIFSC.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label class="form-label">Bank Branch</label>
                                    <Form.Control
                                        type="text"
                                        name='BankBranch'
                                        placeholder="BankBranch"
                                        {...register("BankBranch")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.BankBranch ? errors.BankBranch.message : ''}</p>
                                </Form.Group>
                                </Row> 
                                </div>
                                </div>

                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                                
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
    )
}

export default AddEmploye
