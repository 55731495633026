

import { ImagesData } from "../../../../commoncomponents/images/commonimages";

export const ItemData = [

    //Products
    {
        id: 1,
        preview: ImagesData("products7"),
        name: 'Beautiful Leather Hand Bag',
        newprice: '$1,967',
        oldprice: '$2,498',
        text: '(23)',
        image: [
            { "img": ImagesData("products7") },
            { "img": ImagesData("products71") },
            { "img": ImagesData("products72") },
        ],

    },
    {
        id: 2,
        preview: ImagesData("products8"),
        name: 'Premium Desktop Design',
        newprice: '$1,999',
        oldprice: '$2,999',
        text: '(64)',
        image: [
            { "img": ImagesData("products8") },
            { "img": ImagesData("products81") },
            { "img": ImagesData("products82") },
        ],
    },
    {
        id: 3,
        preview: ImagesData("products9"),
        name: 'Your Phone With Latest Android',
        newprice: '$999',
        oldprice: '$1,499',
        text: '(41)',
        image: [
            { "img": ImagesData("products9") },
            { "img": ImagesData("products91") },
            { "img": ImagesData("products92") },
        ],
    },
    {
        id: 4,
        preview: ImagesData("products1"),
        name: 'Beautiful Flower vase',
        newprice: '$225',
        oldprice: '$498',
        text: '(232)',
        image: [
            { "img": ImagesData("products1") },
            { "img": ImagesData("products111") },
            { "img": ImagesData("products112") },
        ],
    },
    {
        id: 5,
        preview: ImagesData("products2"),
        name: 'Leather Finished Chair Home',
        newprice: '$298',
        oldprice: '$2,498',
        text: '(143)',
        image: [
            { "img": ImagesData("products2") },
            { "img": ImagesData("products211") },
            { "img": ImagesData("products212") },
        ],
    },
    {
        id: 6,
        preview: ImagesData("products3"),
        name: 'Branded Shoes men',
        newprice: '$2,499',
        oldprice: '$2,999',
        text: '(29)',
        image: [
            { "img": ImagesData("products3") },
            { "img": ImagesData("products31") },
            { "img": ImagesData("products33") },
        ],
    },
    {
        id: 7,
        preview: ImagesData("products4"),
        name: 'Igono Laptop Ryzen5 Processor',
        newprice: '$2,999',
        oldprice: '$3,498',
        text: '(20)',
        image: [
            { "img": ImagesData("products4") },
            { "img": ImagesData("products41") },
            { "img": ImagesData("products42") },
        ],
    },
    {
        id: 8,
        preview: ImagesData("products5"),
        name: 'Orange Smart Watch',
        newprice: '$1,967',
        oldprice: '$2,498',
        text: '(283)',
        image: [
            { "img": ImagesData("products5") },
            { "img": ImagesData("products51") },
            { "img": ImagesData("products52") },
        ],
    },
    {
        id: 9,
        preview: ImagesData("products6"),
        name: 'Micon Camera With 10x Zoom',
        newprice: '$1,499',
        oldprice: '$2,498', text: '(14)',
        image: [
            { "img": ImagesData("products6") },
            { "img": ImagesData("products61") },
            { "img": ImagesData("products62") },
        ],
    },
    // Wishlist
    {
        id:10,
        preview:ImagesData("products7"),  
        name:'Beautiful Leather Hand Bag',  
        newprice:'$1,967', 
        oldprice:'$2,498',
        text:'(23)',

    },
    {
        id:11,
        preview:ImagesData("products8"),  
        name:'Premium Desktop Design',  
        newprice:'$1,999', 
        oldprice:'$2,999',
        text:'(64)',

    },
    {
        id:12,
        preview:ImagesData("products9"),  
        name:'Your Phone With Latest Android',  
        newprice:'$999', 
        oldprice:'$1,499',
        text:'(41)', 

    },
    {
        id:13,
        preview:ImagesData("products1"),  
        name:'Beautiful Flower vase',  
        newprice:'$225', 
        oldprice:'$498',
        text:'(232)',

    },
    {id:14,
        preview:ImagesData("products2"),  
        name:'Leather Finished Chair Home',  
        newprice:'$298', 
        oldprice:'$2,498',
        text:'(143)', 
    },
    {id:15,
        preview:ImagesData("products3"),  
        name:'Branded Shoes htmlFor men',  
        newprice:'$2,499', 
        oldprice:'$2,999',
        text:'(29)', 
    },
    {id:16,
        preview:ImagesData("products4"),  
        name:'Igono Laptop Ryzen5 Processor',  
        newprice:'$2,999', 
        oldprice:'$3,498',
        text:'(20)', 
    },
    {id:17,
          preview:ImagesData("products5"),  
        name:'Orange Smart Watch',  
        newprice:'$1,967', 
        oldprice:'$2,498',
        text:'(283)', 
    },
    {id:18,
          preview:ImagesData("products6"),  
        name:'Micon Camera With 10x Zoom',  
        newprice:'$1,499', 
        oldprice:'$2,498',
        text:'(14)', 
    },
    {id:19,
         preview:ImagesData("products2"), 
        name:'Leather Finished Chair Home',  
        newprice:'$298', 
        oldprice:'$2,498',
        text:'(143)', 
    },
    {id:20,
         preview:ImagesData("products3"), 
        name:'Branded Shoes men',  
        newprice:'$2,499', 
        oldprice:'$2,999',
        text:'(29)', 
    },
    {id:21,
         preview:ImagesData("products4"), 
        name:'Igono Laptop Ryzen5 Processor',  
        newprice:'$2,999', 
        oldprice:'$3,498',
        text:'(20)', 
    },

]