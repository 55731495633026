import React, { FC, Fragment, useState,useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";

function EditSupplierContact() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({
    })
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      clearErrors,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    useEffect(() => {
      const fetchData = async () => {
          try {
              setLoading(true);
              const response = await axios.get(
                  `${process.env.REACT_APP_SERVER_URL}/api/Supplier/GetSupplierById?SupplierId=${parseInt(location.state.id)}`
              );
              const companyData = response.data.data;
              setValue("supName", companyData.supName);
              setValue("supAddress", companyData.supAddress);
              setValue("supPhoneNumber", companyData.supPhoneNumber);
              setValue("supEmail", companyData.supEmail);
              setValue("supCurrencyCode", companyData.supCurrencyCode);
              setLoading(false);
          } catch (error) {
              console.error("Error fetching company data:", error);
              setLoading(false);
              // Handle the error appropriately (e.g., show an error message to the user)
          }
      };
      fetchData();
  }, [location.state.id, setValue, setLoading]);
    const onSubmit = async (data) => {
      const upload = {
        userId: "string",
        offset: 0,
        recordCount: 0,
        supplierName:data?.supName,
        supplierAddress: data?.supAddress,
        supplierPhoneNumber: data?.supPhoneNumber,
        supplierEmail: data?.supEmail,
        supplierCurrencyCode: data?.supCurrencyCode,
        supplierId: parseInt(location.state.id)
      }
      setLoading(true)
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Supplier/UpdateSupplier`, upload);
        if (response.data.status == 1) {
          toast.success(response.data.message);
          navigate('/supplier-list')
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
    const [img, setImg] = useState()
    const handleImageChange = (e) => {
      setImg(e.target.files[0])
    };
  return (
    <Fragment>
    <Fragment>
      <div className='page-header'>
        <div className="page-leftheader">
          <h4 className="page-title mb-0">Edit Supplier</h4>
        </div>
        <div className="page-rightheader">
          <div className='d-flex align-items-center'>

          </div>
        </div>
      </div>
    </Fragment>
    <Row>
      <Col xl={4} md={12} sm={12}>
      </Col>
      <Col xl={4} md={12} sm={12}>
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  type="text"
                  name='supName'
                  placeholder="Name"
                  {...register("supName")}
                />
                <p className="errorMsg pl-3 mt-2">{errors.supName ? errors.supName.message : ''}</p>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  type="text"
                  name='supAddress'
                  placeholder="Address
                              "
                  {...register("supAddress")}
                />
                <p className="errorMsg pl-3 mt-2">{errors.supAddress ? errors.supAddress.message : ''}</p>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  type="text"
                  name='supPhoneNumber'
                  placeholder="Phone Number"
                  {...register("supPhoneNumber")}
                />
                <p className="errorMsg pl-3 mt-2">{errors.supPhoneNumber ? errors.supPhoneNumber.message : ''}</p>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  type="text"
                  name='supEmail'
                  placeholder="Email"
                  {...register("supEmail")}
                />
                <p className="errorMsg pl-3 mt-2">{errors.supEmail ? errors.supEmail.message : ''}</p>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  type="text"
                  name='supCurrencyCode'
                  placeholder="Currency Code"
                  {...register("supCurrencyCode")}
                />
                <p className="errorMsg pl-3 mt-2">{errors.supCurrencyCode ? errors.supCurrencyCode.message : ''}</p>
              </Form.Group>
              <div className="d-flex justify-content-center formfilter mt-5">
                <Button type="submit" className="mx-2">{loading ?
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                    <Oval

                      heigth="20"
                      width="20"
                      color='#12C412'
                      arialLabel='loading'
                    />
                  </div> : "Submit"}</Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xl={4} md={12} sm={12}>
      </Col>
    </Row>

  </Fragment>
  )
}

export default EditSupplierContact
