import React, { FC, Fragment, useState } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";

function AddSupplier() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const schema = yup.object().shape({

  })
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      offset: 0,
      recordCount: 0,
      company_Name: data?.company_Name,
      company_Email: data?.company_Email,
      company_Phone: data?.company_Phone,
      contactIsPrimary: true,
      supplierId: 0
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Supplier/CreateSupplierContact`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/supplier-list')
        setLoading(false)
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const [img, setImg] = useState()
  const handleImageChange = (e) => {
    setImg(e.target.files[0])

  };
  return (
    <Fragment>
      <Fragment>
        <div className='page-header'>
          <div className="page-leftheader">
            <h4 className="page-title mb-0">Add Supplier</h4>
          </div>
          <div className="page-rightheader">
            <div className='d-flex align-items-center'>

            </div>
          </div>
        </div>
      </Fragment>
      <Row>
        <Col xl={4} md={12} sm={12}>
        </Col>
        <Col xl={4} md={12} sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Name'
                    placeholder="Name"
                    {...register("company_Name")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Name ? errors.company_Name.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Email'
                    placeholder="Email
                                        "
                    {...register("company_Email")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Email ? errors.company_Email.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Phone'
                    placeholder="Phone"
                    {...register("company_Phone")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Phone ? errors.company_Phone.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Telephone'
                    placeholder="Mobile"
                    {...register("company_Telephone")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Telephone ? errors.company_Telephone.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Currency'
                    placeholder="Currency"
                    {...register("company_Currency")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Currency ? errors.company_Currency.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom02" className='mb-2'>
                  <Form.Label>Logo</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"

                    onChange={(e) => handleImageChange(e)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Country'
                    placeholder="Country"
                    {...register("company_Country")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Country ? errors.company_Country.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_City'
                    placeholder="City"
                    {...register("company_City")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_City ? errors.company_City.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='company_Address'
                    placeholder="Address"
                    {...register("company_Address")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.company_Address ? errors.company_Address.message : ''}</p>
                </Form.Group>
                <div className="d-flex justify-content-center formfilter mt-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12} sm={12}>
        </Col>
      </Row>

    </Fragment>
  )
}

export default AddSupplier
