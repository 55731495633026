import React, { Fragment } from 'react'
import Main_dashboard from '../Components/dashboard/Main_dashboard';
import DataTable from '../Components/list/datatable';
import AddProducts from '../Components/addproducts/addproducts';
import DesignationList from '../Components/Master/Designation/DesignationList';
import AddDesignation from '../Components/Master/Designation/AddDesignation';
import EditDesignation from '../Components/Master/Designation/EditDesignation';
import Departmentlist from '../Components/Master/Department/Departmentlist';
import AddDepartment from '../Components/Master/Department/AddDepartment';
import EditDepartment from '../Components/Master/Department/EditDepartment';
import EditCompany from '../Components/Master/Company/EditCompany';
import AddCompany from '../Components/Master/Company/AddCompany';
import Companylist from '../Components/Master/Company/Companylist';
import Supplierlist from '../Components/Master/Supplier/Supplierlist';
import EditSupplier from '../Components/Master/Supplier/EditSupplier';
import AddSupplier from '../Components/Master/Supplier/AddSupplier';
import Unitlist from '../Components/Master/Unit/Unitlist';
import AddUnit from '../Components/Master/Unit/AddUnit';
import EditUnit from '../Components/Master/Unit/EditUnit';
import Projectlist from '../Components/Master/Project/Projectlist';
import EditProjects from '../Components/Master/Project/EditProjects';
import AddProject from '../Components/Master/Project/AddProject';
import OrderDetails from '../Components/Order/order-details/OrderDetails';
import SupplierContactlist from '../Components/Master/SupplierContact/SupplierContactlist';
import AddSupplierContact from '../Components/Master/SupplierContact/AddSupplierContact';
import EditSupplierContact from '../Components/Master/SupplierContact/EditSupplierContact';
import MRlist from '../Components/Master/MR/MRlist';
import AddMR from '../Components/Master/MR/AddMR';
import Employelist from '../Components/Master/Employe/Employelist';
import AddEmploye from '../Components/Master/Employe/AddEmploye';
import EditEmploye from '../Components/Master/Employe/EditEmploye';
import EditMr from '../Components/Master/MR/EditMr';


export const RouterData = [

    { path: `dashboard`, element: <Main_dashboard /> },
    { path: `add-product`, element: <AddProducts /> },
    { path: `product-list`, element: <DataTable /> },
    { path: `designation-list`, element: <DesignationList /> },
    { path: `add-designation`, element: <AddDesignation /> },
    { path: `edit-designation`, element: <EditDesignation /> },
    { path: `department-list`, element: <Departmentlist /> },
    { path: `add-department`, element: <AddDepartment /> },
    { path: `edit-department`, element: <EditDepartment /> },
    { path: `company-list`, element: <Companylist /> },
    { path: `add-company`, element: <AddCompany /> },
    { path: `edit-company`, element: <EditCompany /> },
    { path: `supplier-list`, element: <Supplierlist /> },
    { path: `add-supplier`, element: <AddSupplier /> },
    { path: `edit-supplier`, element: <EditSupplier /> },
    { path: `unit-list`, element: <Unitlist /> },
    { path: `add-unit`, element: <AddUnit /> },
    { path: `edit-unit`, element: <EditUnit /> },
    { path: `project-list`, element: <Projectlist /> },
    { path: `add-project`, element: <AddProject /> },
    { path: `edit-project`, element: <EditProjects /> },
    { path: `order-details`, element: <OrderDetails /> },
    { path: `supplier-contact-list`, element: <SupplierContactlist /> },
    { path: `add-supplier-contact`, element: <AddSupplierContact /> },
    { path: `edit-supplier-contact`, element: <EditSupplierContact /> },
    { path: `employe-list`, element: <Employelist /> },
    { path: `add-employe`, element: <AddEmploye /> },
    { path: `edit-employe`, element: <EditEmploye /> },
    { path: `MR`, element: <AddMR /> },    
    { path: `MR-list`, element: <MRlist /> },  
    { path: `edit-mr`, element: <EditMr /> },  

];